<template>
  <div class="dairy_about_wrapper clv_section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6col-md-6">
          <div class="clv_heading text-center">
            <h3>Регистрация карты</h3>
            <div class="clv_underline">
              <img src="img/dairy_underline3.png" alt="image" />
            </div>
          </div>
          <div>
            <h5 class="sub-title text-center">
              Это займет не более трех минут — мы проверяли!
            </h5>
          </div>

          <div id="stepOne" v-if="step===0">
            <Form
              @submit="onSubmitStepOne"
              :validation-schema="schema.stepOne"
              @invalid-submit="onInvalidSubmit"
              name="stepOne"
            >
            <TextInput
                name="cardNumber"
                type="text"
                label="Карта лояльность"
                placeholder="Номер карты лояльности"
                success-message=""
                mask="#########"
              />

              <TextInput
                name="phone"
                type="phone"
                label="Телефон"
                placeholder="Номер телефона"
                success-message=""
                mask="+7 (###) ###-##-##"
                @maska="phoneRaw = $event.target.dataset.maskRawValue"
              />

              <Field as="terms" v-slot="{ field }" name="terms" type="checkbox" :value="false">
                <label>
                  <input type="checkbox" name="terms" v-bind="field" :value="false" />
                  Соглашаюсь на обработку персональных данных и на передачу данных третьим лицам*
                </label>
                <p class="help-message" v-show="field">
                  <span>{{ field.field }}</span>
                </p>
                <error-message name="drink"/>
              </Field>

               <button class="submit-btn" type="submit">Продолжить</button>
            </Form>
          </div>

          <div id="stepTwo" v-if="step===1">
            <Form @submit="onSubmitStepTwo"
                  :validation-schema="schema.stepTwo"
                  @invalid-submit="onInvalidSubmit"
            >
              <TextInput
                      name="checkPhone"
                      type="text"
                      label="Вам поступит звонок"
                      placeholder=""
                      success-message=""
                      mask="####"
              />
              <p>Введите в поле последние 4 цифры входящего номера</p>
              <button class="submit-btn" type="submit">Продолжить</button>
            </Form>
          </div>

          <div id="stepThree" v-if="step===2">
            <Form @submit="onSubmitStepThree"
              :validation-schema="schema.stepThree"
              @invalid-submit="onInvalidSubmit"
            >
              <TextInput
                name="name"
                type="text"
                label="ФИО"
                placeholder="Фамилия Имя Отчество"
                success-message="Рад встрече!"
                error-message="Рад встрече!"
              />

              <TextInput
                name="birthday"
                type="date"
                label="Дата рождения"
                placeholder="Дата рождения"
                success-message=""
              />

              <div class="TextInput gender">
                  <label for="gender">пол</label>
                  <div class="radio radio-inline">
                    <label id="gender">
                      <Field name="gender" type="radio" value="man"/>
                    <span class="cr"><i class="cr-icon fa fa-circle" /></span>
                    муж.
                    </label>
                  </div>
                  <div class="radio radio-inline">
                    <label>
                      <Field name="gender" type="radio" value="woman"/>
                    <span class="cr"><i class="cr-icon fa fa-circle"/></span>
                    жен.
                    </label>
                  </div>
              </div>



              <TextInput
                name="email"
                type="email"
                label="E-mail"
                placeholder="Ваш адрес электронной почты"
                success-message=""
                success-message1="Понятно, спамить не будем!"
              />
              <button class="submit-btn" type="submit">Отправить</button>
            </Form>
          </div>

          <div id="stepFour" v-if="step===3">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
require("yup-phone");
import TextInput from "@/components/TextInput.vue";


import axios from "axios";

import { defineRule } from "vee-validate";
defineRule("required", (value) => {
  if (!value || !value.length) {
    return "Это поле обязательно к заполнению";
  }
  return true;
});
defineRule("email", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return "В этом поле должен быть действующий адрес электронной почты.";
  }
  return true;
});

const rePhoneNumber = /^((\+7|7|8)+([0-9]){10})$/;

Yup.addMethod(Yup.string, "phone", function () {
  return this.test("phone", "Номер телефона недействителен", (value) => {
    return rePhoneNumber.test(value.replace(/\D/g,''))
  });
});

export default {
  name: "Registration",
  data(){
    return {
      currentStep: 0,
       form:{

       }
    }
  },
  components: {
    TextInput,
    Form, Field, ErrorMessage
  },
  computed:{
    step:{
      get(){
        return this.currentStep
      },
      set(i){
        console.log(i)
        this.currentStep = i
      }
    }
  },
  methods:{
    onSubmitStepOne(values){
      let $vm = this;
      this.axios.post("api/register/add", values)
              .then(function(response){
                if(response.data.status){
                  $vm.step = 1;
                }
              })
              .catch((err) => {
                console.error(err);
              });
    },
    onSubmitStepTwo(values){
      let $vm = this;
      this.axios.post("api/register/check", values)
              .then(function(response){
                if(response.data.status){
                  $vm.step = 2;
                }else{
                  this.$swal('Ошибка!');
                }
              })
              .catch((err) => {
                console.error(err);
              });
    },
    onSubmitStepThree(values){
      let $vm = this;
      this.axios.post("api/register/save", values)
              .then(function(response){
                if(response.data.status){
                  $vm.step = 3;
                  document.getElementById("app").style.display = "none";
                  $vm.$swal(`Ваша бонусная карта будет активирована в течении 3х дней!<br>Приятных вам покупок!`);
                }else{
                  $vm.$swal('Ошибка!');
                }
              })
              .catch((err) => {
                console.error(err);
              });
    }
  },
  setup() {

    async function onSubmit(values) {
      axios
        .post("api/register", values)
        .then((response) => {
          alert(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    function onInvalidSubmit() {
      // ignore-no-debugger

      const submitBtn = document.querySelector(".submit-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    }

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    //'Пожалуйста, укажите ваш номер телефона — мы пришлём на него смс с кодом подтверждения'
    const schema = {};
    schema.stepOne = Yup.object().shape({
      phone: Yup.string().phone().required('укажите ваш номер телефона'),
      cardNumber: Yup.string().required('Введите номер карты').matches(new RegExp('[0-9]{3}'), 'Введите номер карты'),
      terms: Yup.boolean().required('Обязательное поле')
    });

    schema.stepTwo = Yup.object().shape({
      checkPhone: Yup.string()
              .required('')
              .matches(new RegExp('[0-9]{4}'), 'Обязательное поле')
    });

    schema.stepThree = Yup.object().shape({
      name: Yup.string().required(),
      //surname: Yup.string().required(),
      //email: Yup.string().email().required(),
      //phone: Yup.string().phone().required('укажите ваш номер телефона'),
      //LoyaltyCardNumber: Yup.string().required('Введите номер карты'),
      //terms: Yup.boolean().required('Обязательное поле')
    });

    return {
      onSubmit,
      schema,
      onInvalidSubmit,
    };
  },
};
</script>

<style scoped>

.TextInput.gender {
  padding: 15px 10px;
  border: #cacaca61;
  border-style: dotted;
  margin-bottom: calc(1em * 1.5);
}

.radio.radio-inline {
  margin-top: 0;
}

.checkbox label:after,
.radio label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr,
.radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
}

.radio .cr {
  border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  left: 13%;
}

.radio .cr .cr-icon {
  margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon,
.radio label input[type="radio"]+.cr>.cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.radio label input[type="radio"]:checked+.cr>.cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
  opacity: .5;
}
</style>

<style>
:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
</style>

<style scoped>
.clv_heading {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sub-title {
  color: #7e7e7e;
  font-family: Gotham Book, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 28px;
  margin: 0 0 40px;
}
</style>


<style scoped>
* {
  box-sizing: border-box;
}

form {
  width: 300px;
  margin: 0px auto;
  padding-bottom: 60px;
}

.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn.invalid {
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>


